import { render, staticRenderFns } from "./CampanhaPendencias.vue?vue&type=template&id=53b043ff&scoped=true&"
import script from "./CampanhaPendencias.vue?vue&type=script&lang=js&"
export * from "./CampanhaPendencias.vue?vue&type=script&lang=js&"
import style0 from "./CampanhaPendencias.vue?vue&type=style&index=0&id=53b043ff&prod&lang=scss&scoped=true&"
import style1 from "./CampanhaPendencias.vue?vue&type=style&index=1&id=53b043ff&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b043ff",
  null
  
)

export default component.exports